import { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useStoreDispatch, useStoreSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { AppState } from './store/Store';
import { AuthApi } from './libs/AuthApi';
import { setUser } from './store/auth/AuthSlice';

const App: FC = () => {
  const dispatch = useStoreDispatch();
  const [customizer, auth] = useStoreSelector((state: AppState) => [state.customizer, state.auth]);
  const [protectedRouter, publicRouter] = Router;
  const routing = useRoutes(auth.isAuthenticated ? [protectedRouter] : [publicRouter]);
  const theme = ThemeSettings();

  const user = AuthApi.getUser();
  AuthApi.restoreCredentials();
  if (!auth.user && user) {
    dispatch(setUser(user));
  }

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
