import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../../types/auth/AuthState';
import { AppDispatch } from '../Store';
import { LoginSchema } from '../../views/authentication/authForms/AuthLogin';
import { AuthApi } from '../../libs/AuthApi';

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};

export const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
    },
  },
});

export const login = ({ email, password, remember }: LoginSchema) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AuthApi.login(email, password);
    AuthApi.setCredentials(response, remember);
    dispatch(setUser(response.user));
  } catch (error: any) {
    dispatch(setError(error.message));
  }
};

export const { setUser, setLoading, setError, clearError, logout } = AuthSlice.actions;
const AuthReducer = AuthSlice.reducer;
export default AuthReducer;
