import axios, {AxiosResponse} from 'axios';
import {AuthApi, LoginResponse} from "./AuthApi";

axios.defaults.baseURL = process.env.REACT_APP_API_ROOT
axios.defaults.headers.common['Content-Type'] = 'application/json'

export class ApiError extends Error {
    data?: any

    constructor(message: string, data?: any) {
        super(message)
        this.data = data
    }
}

const handleError = async (err: any) => {
    if (err.response?.data) {
        const {statusCode, message} = err.response.data;
        if (statusCode === 401 && message === 'Token expired') {
            const [refreshToken, remember] = AuthApi.getRefreshToken();
            const data = await axios.post("auth/refresh-token", {refreshToken}).then(res => res.data).catch(err => {
                throw new ApiError('Failed to refresh token', err);
            });
            AuthApi.setCredentials(data as LoginResponse, Boolean(remember));

            return axios(err.config).then(res => res.data).catch(() => {
                throw new ApiError(err.response.data.message, err.response.data)
            });
        }
        throw new ApiError(err.response.data.message, err.response.data)
    }
    throw new ApiError(err.message)
}

export class ApiCore {
    protected static async _get<T = any>(url: string, params?: any): Promise<T> {
        return axios.get<any, AxiosResponse<T>>(url, {params}).then(res => res.data).catch(handleError)
    }

    protected static async _post<T = any>(url: string, data: any) {
        return axios.post<any, AxiosResponse<T>>(url, data).then(res => res.data).catch(handleError)
    }

    protected static async _patch<T = any>(url: string, data?: any) {
        return axios.patch<any, AxiosResponse<T>>(url, data).then(res => res.data).catch(handleError)
    }

    protected static async _delete<T = any>(url: string, data?: any) {
        return axios.delete<any, AxiosResponse<T>>(url, data).then(res => res.data).catch(handleError)
    }
}
