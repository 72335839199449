import { ApiCore } from './ApiCore';
import { UserDataType } from '../types/UserDataType';

export const USER_DATA_KEY = 'user-data';

export class UserApi extends ApiCore {
  public static async loadUserData() {
    const storedUser = localStorage.getItem(USER_DATA_KEY);
    if (storedUser) {
      return JSON.parse(storedUser) as UserDataType;
    }

    return this._get<UserDataType>('user/profile');
  }
}
