import {ApiCore} from './ApiCore';
import axios from 'axios';
import {USER_DATA_KEY} from './UserApi';
import {UserDataType} from '../types/UserDataType';

const ACCESS_TOKEN_KEY = 'access-token-key';
const REFRESH_TOKEN_KEY = 'refresh-token-key';

export interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user: UserDataType;
}

export class AuthApi extends ApiCore {
    public static login(email: string, password: string) {
        return this._post<LoginResponse>('auth/login', {email, password});
    }

    public static getRefreshToken() {
        const session = sessionStorage.getItem(REFRESH_TOKEN_KEY);
        if (session) {
            return [session, false];
        }
        return [localStorage.getItem(REFRESH_TOKEN_KEY), true];
    }

    public static setCredentials(credential: LoginResponse, isSave?: boolean) {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + credential.accessToken;
        const storage = isSave ? localStorage : sessionStorage;
        storage.setItem(ACCESS_TOKEN_KEY, credential.accessToken);
        storage.setItem(REFRESH_TOKEN_KEY, credential.refreshToken);
        storage.setItem(USER_DATA_KEY, JSON.stringify(credential.user));
    }

    public static restoreCredentials() {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (accessToken) {
            axios.defaults.headers.common['authorization'] = 'Bearer ' + accessToken;
            return true;
        }

        return false;
    }

    public static logout() {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(USER_DATA_KEY);
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
        sessionStorage.removeItem(USER_DATA_KEY);
    }

    static getUser(): UserDataType | undefined {
        const sessionData = sessionStorage.getItem(USER_DATA_KEY)
        if (sessionData) {
            return JSON.parse(sessionData) as UserDataType
        }
        const savedData = localStorage.getItem(USER_DATA_KEY)
        if (savedData) {
            return JSON.parse(savedData) as UserDataType
        }
    }
}
